import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'

const TOC = () => {
  return (
    <div css={section}>
      <p>Cooperative Shenanigans Blog -- coming soon!</p>
      <p>Playtest & Prototyping Tools -- coming soon!</p>
      <p>
        <Link to="/contact">Contact Us</Link> -- tell us how we can help you!
      </p>

      <p>
        <Link to="/">None of these</Link> -- take me back to the homepage.
      </p>
    </div>
  )
}

const section = css`
  width: 90%;
  color: #efefec;
  /* background-color: rgba(111, 118, 173, .7); */
  background-color: rgba(20, 32, 80, 0.85);
  border-radius: 5px;
  padding: 2rem;
  margin-bottom: 2.5rem;

  a {
    text-decoration: underline;
  }

  p {
    margin: 0.5rem 0rem;
  }
`

export default TOC
