import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { css } from '@emotion/core'
import TOC from '../components/toc'

const NotFoundPage = () => (
  <Layout>
    <div css={content}>
      <SEO title="404: Shenanigan not found" />
      <h1 css={big}>page not found!</h1>

      <div css={section}>
        <h1>Missing pages aren't the kind of shenanigans we enjoy!</h1>
        <p>Sorry about that! Maybe one of these pages will help?</p>
      </div>
      <TOC />
    </div>
  </Layout>
)

const content = css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  --hex-width: 400px;
`

const section = css`
  width: 90%;
  color: #efefec;
  /* background-color: rgba(111, 118, 173, .7); */
  background-color: rgba(20, 32, 80, 0.85);
  border-radius: 5px;
  padding: 2rem;
  margin-bottom: 2.5rem;

  a {
    text-decoration: underline;
  }
`

const big = css`
  font-size: 8rem;
  font-variant: small-caps;
  color: rgba(20, 32, 80, 0.85);
  margin-top: -4rem;
`
export default NotFoundPage
